// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-empty-legs-jsx": () => import("./../../../src/pages/empty-legs.jsx" /* webpackChunkName: "component---src-pages-empty-legs-jsx" */),
  "component---src-pages-gracias-jsx": () => import("./../../../src/pages/gracias.jsx" /* webpackChunkName: "component---src-pages-gracias-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-quienes-somos-jsx": () => import("./../../../src/pages/quienes-somos.jsx" /* webpackChunkName: "component---src-pages-quienes-somos-jsx" */),
  "component---src-pages-servicio-jsx": () => import("./../../../src/pages/servicio.jsx" /* webpackChunkName: "component---src-pages-servicio-jsx" */)
}

